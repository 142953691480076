import Vue from 'vue';
import Vuex from 'vuex';
import { ElementApiModule } from 'clockvine-vue';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        products: new ElementApiModule('/products.json', { debounce: 1000 }),
        productTypes: new ElementApiModule('/productTypes.json', { debounce: 1000 }),
        productTopics: new ElementApiModule('/productTopics.json', { debounce: 1000 }),
        news: new ElementApiModule('/news.json', { debounce: 1000 }),
        newsCategories: new ElementApiModule('/newsCategories.json', { debounce: 1000 }),
        systemCategories: new ElementApiModule('/systemCategories.json', { debounce: 1000 }),
    },
});
