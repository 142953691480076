// @ts-ignore
import $ from 'jquery';
import WOW  from 'wowjs';
import Swiper from 'swiper';
import owlCarousel from 'owl.carousel';
import magnificPopup from 'magnific-popup';
new WOW.WOW().init();
!(function ($)  {
	"use strict";

	// 01.Header
	// $('.sticky').fix({
	// 	startOffset: 100,
	// 	duration: 500
	// });

	////////////////////////////////////////////////////
	// 05. Search Js
	$(".search-toggle").on("click", function () {
		$(".search__area").addClass("opened");
	});
	$(".search-close-btn").on("click", function () {
		$(".search__area").removeClass("opened");
	});
	//play video on hover 
	$(document).on('mouseover', 'video', function() { 
		$(this).get(0).play(); 
	   }); 
  
	   //pause video on mouse leave 
	   $(document).on('mouseleave', 'video', function() { 
		$(this).get(0).pause(); 
	   });
	

	////////////////////////////////////////////////////
	// 06. Sticky Header Js
	var windowOn = $(window);
	windowOn.on('scroll', function () {
		var scroll = $(window).scrollTop();
		if (scroll < 100) {
			$("#header-sticky").removeClass("header__sticky");
		} else {
			$("#header-sticky").addClass("header__sticky");
		}
	});
	////////////////////////////////////////////////////
	// 07. Data CSS Js
	$("[data-background").each(function () {
		$(this).css("background-image", "url( " + $(this).attr("data-background") + "  )");
	});

	$("[data-width]").each(function () {
		$(this).css("width", $(this).attr("data-width"));
	});

	$("[data-bg-color]").each(function () {
		$(this).css("background-color", $(this).attr("data-bg-color"));
	});	

	// $('.slider-for').slick({
	// 	slidesToShow: 1,
	// 	slidesToScroll: 1,
	// 	arrows: false,
	// 	fade: true,
	// 	asNavFor: '.slider-nav'
	//   });
	//   $('.slider-nav').slick({
	// 	slidesToShow: 4,
	// 	slidesToScroll: 1,
	// 	asNavFor: '.slider-for',
	// 	dots: false,
	// 	centerMode: true,
	// 	centerPadding: '30px',
	// 	focusOnSelect: true
	//   });
	
	

	////////////////////////////////////////////////////
	// 07. Data CSS Js
	$("[data-background").each(function () {
		$(this).css("background-image", "url( " + $(this).attr("data-background") + "  )");
	});
	
	$("[data-width]").each(function () {
		$(this).css("width", $(this).attr("data-width"));
	});

	$("[data-bg-color]").each(function () {
        $(this).css("background-color", $(this).attr("data-bg-color"));
    });


	////////////////////////////////////////////////////
	// 23. InHover Active Js
	$('.hover__active').on('mouseenter', function () {
		$(this).addClass('active').parent().siblings().find('.hover__active').removeClass('active');
	});


	// Jquery Appear
	//----------------------------------------------------------------------------------------
	if ($('.progress-bar').length) {
		$('.progress-bar').appear(function () {
			var el = $(this);
			var percent = el.data('width');
			$(el).css('width', percent + '%');
		}, {
			accY: 0
		});
	}

    //===== Progress Bar
    if ($('.progress_line').length) {
        $('.progress_line').appear(function () {
            var el = $(this);
            var percent = el.data('width');
            $(el).css('width', percent + '%');
        }, {
            accY: 0
        });
    }

	// Jquery Appear raidal
	if (typeof ($.fn.knob) != 'undefined') {
		$('.knob').each(function () {
		var $this = $(this),
		knobVal = $this.attr('data-rel');

		$this.knob({
		'draw': function () {
			$(this.i).val(this.cv + '%')
		}
		});

		$this.appear(function () {
		$({
			value: 0
		}).animate({
			value: knobVal
		}, {
			duration: 2000,
			easing: 'swing',
			step: function () {
			$this.val(Math.ceil(this.value)).trigger('change');
			}
		});
		}, {
		accX: 0,
		accY: -150
		});
	});
	}



	// colse button
	if (jQuery(".close-button").length > 0) {
		const closeBtn = document.querySelector('.close-button');
		closeBtn.addEventListener('click',function(){
			const append = document.querySelector('.appoinment-cta-2');
			append.classList.add('hide')
		})
	}
	

})($, window, jQuery);

!function(s) {
    "use strict";
	s(".switch").on("click",
		function() {
			s("body").hasClass("light") ? (s("body").removeClass("light"), s(".switch").removeClass("switched")) : (s("body").addClass("light"), s(".switch").addClass("switched"))
	}),
    s(document).ready(function() {
		/* magnificPopup video view */
		s(".popup-video").magnificPopup({
			type: "iframe",
		});
		/* ------------------  Background INSERT ------------------ */

		var $bgSection = $(".bg-section");
		var $bgPattern = $(".bg-pattern");
		var $colBg = $(".col-bg");
	
		$bgSection.each(function() {
			var bgSrc = $(this).children("img").attr("src");
			var bgUrl = 'url(' + bgSrc + ')';
			$(this).parent().css("backgroundImage", bgUrl);
			$(this).parent().addClass("bg-section");
			$(this).remove();
		});
	
		$bgPattern.each(function() {
			var bgSrc = $(this).children("img").attr("src");
			var bgUrl = 'url(' + bgSrc + ')';
			$(this).parent().css("backgroundImage", bgUrl);
			$(this).parent().addClass("bg-pattern");
			$(this).remove();
		});
	
		$colBg.each(function() {
			var bgSrc = $(this).children("img").attr("src");
			var bgUrl = 'url(' + bgSrc + ')';
			$(this).parent().css("backgroundImage", bgUrl);
			$(this).parent().addClass("col-bg");
			$(this).remove();
		});
				/* ------------------ OWL CAROUSEL ------------------ */

				var $carouselDirection = $("html").attr("dir");
				if ($carouselDirection == "rtl") {
					var $carouselrtl = true;
				} else {
					var $carouselrtl = false;
				}
			
				$(".carousel").each(function() {
					var $Carousel = $(this);
					$Carousel.owlCarousel({
						loop: $Carousel.data('loop'),
						autoplay: $Carousel.data("autoplay"),
						margin: $Carousel.data('space'),
						nav: $Carousel.data('nav'),
						dots: $Carousel.data('dots'),
						dotsSpeed: $Carousel.data('speed'),
						mouseDrag: $Carousel.data('drag'),
						touchDrag: $Carousel.data('drag'),
						pullDrag: $Carousel.data('drag'),
						rtl: $carouselrtl,
						responsive: {
							0: {
								items: 1,
							},
							768: {
								items: $Carousel.data('slide-rs'),
							},
							1000: {
								items: $Carousel.data('slide'),
								center: $Carousel.data('center'),
							}
						}
					});
				});
			
				$(".slider-carousel").each(function() {
					var $Carousel = $(this);
					$Carousel.owlCarousel({
						loop: $Carousel.data('loop'),
						autoplay: $Carousel.data("autoplay"),
						margin: $Carousel.data('space'),
						nav: $Carousel.data('nav'),
						dots: $Carousel.data('dots'),
						center: $Carousel.data('center'),
						dotsSpeed: $Carousel.data('speed'),
						rtl: $carouselrtl,
						responsive: {
							0: {
								items: 1,
							},
							768: {
								items: $Carousel.data('slide-rs'),
							},
							1000: {
								items: $Carousel.data('slide'),
							}
						},
						animateOut: 'fadeOut',
						//animateOut: 'fadeOutLeft',
						//animateIn: 'flipInX',
						//smartSpeed: 450
					});
				});
		if (s(".thumbs__slider").length > 0) {
			var thumbs__slider = new Swiper('.thumbs__slider', {
				spaceBetween: 10,
				slidesPerView: 5,
				freeMode: true,
				watchSlidesProgress: true,
				navigation: {
					nextEl: '.bs-button-next',
					prevEl: '.bs-button-prev',
				},
			});
		}
		if (s(".swiperTop").length > 0) {
			var brand__slider = new Swiper('.swiperTop', {
				slidesPerView: 1,
				loop: true,
				spaceBetween: 30,
				autoplay: {
						delay: 6000,
				},
				thumbs: {
					swiper: thumbs__slider,
				  },
			});
		}
        var e = document.querySelector(".progress-wrap path"),
        t = e.getTotalLength();
        e.style.transition = e.style.WebkitTransition = "none",
        e.style.strokeDasharray = t + " " + t,
        e.style.strokeDashoffset = t,
        e.getBoundingClientRect(),
        e.style.transition = e.style.WebkitTransition = "stroke-dashoffset 5ms linear";
        var o = function() {
            var o = s(window).scrollTop(),
            r = s(document).height() - s(window).height(),
            i = t - o * t / r;
            e.style.strokeDashoffset = i
        };
        o(),
        s(window).scroll(o);
        jQuery(window).on("scroll",
        function() {
            jQuery(this).scrollTop() > 50 ? jQuery(".progress-wrap").addClass("active-progress") : jQuery(".progress-wrap").removeClass("active-progress")
        }),
        jQuery(".progress-wrap").on("click",
        function(s) {
            return s.preventDefault(),
            jQuery("html, body").animate({
                scrollTop: 0
            },
            550),
            !1
        }),
		jQuery(".header-search-button").on("click",
		function() {
			if (jQuery(".header-search-wrapper").hasClass("novis_search")){
				jQuery(".header-search-wrapper").fadeIn(1).addClass("vis-search").removeClass("novis_search");
				jQuery(".header-search-button").addClass("hsbclose");
			}
			else {
				jQuery(".header-search-wrapper").fadeOut(1).removeClass("vis-search").addClass("novis_search");
				jQuery(".header-search-button").removeClass("hsbclose");
			}
		});
		jQuery(".mor-opt-btn_act").on("click",
		function() {
			if (jQuery(".more-search-opt-wrap").hasClass("more-hidden_wrap")) {
				jQuery(".more-search-opt-wrap").addClass("vis-more-search").removeClass("more-hidden_wrap");
			}
			else {
				jQuery(".more-search-opt-wrap").removeClass("vis-more-search").addClass("more-hidden_wrap");
			}
		});
		jQuery(".close_msotw").on("click",
		function() {
			jQuery(".more-search-opt-wrap").removeClass("vis-more-search").addClass("more-hidden_wrap");
		});
		function SelectTag(t) {
			this.child = t.child || ".default",
				this.over = t.over || "on",
				this.all = t.all || ".all",
				this.init()
		}
		jQuery.extend(SelectTag.prototype, {
			init: function() {
				var t = this;
				t._bindEvent(),
					t._select()
			},
			_bindEvent: function() {
				var t = this;
				jQuery(t.child).click(function(e) {
					e.preventDefault();
					var i = window.location.href
						, n = jQuery(this).attr("rel")
						, r = jQuery(this).attr("name");
					jQuery(this).hasClass(t.over) || (window.location.href = t._changeURLPar(i, r, n))
				}),
					jQuery(t.all).click(function(e) {
						e.preventDefault();
						var i = window.location.href
							, n = jQuery(this).attr("name");
						jQuery("[name=" + n + "]").removeClass(t.over),
							jQuery(this).addClass(t.over),
							window.location.href = t._delUrlPar(i, n)
					})
			},
			_delUrlPar: function(t, e) {
				var n = "";
				if (t.indexOf("?") == -1)
					return t;
				n = t.substr(t.indexOf("?") + 1);
				var r = ""
					, a = "", i = "";
				if (n.indexOf("&") != -1) {
					r = n.split("&");
					for (i in r)
						r[i].split("=")[0] != e && (a = a + r[i].split("=")[0] + "=" + r[i].split("=")[1] + "&");
					return t.substr(0, t.indexOf("?")) + "?" + a.substr(0, a.length - 1)
				}
				return r = n.split("="),
					r[0] == e ? t.substr(0, t.indexOf("?")) : t
			},
			_changeURLPar: function(t, e, i) {
				var n = this
					, r = e + "=([^&]*)"
					, a = e + "=" + i
					, s = encodeURI(n._getQueryString(e));
				return t.match(r) ? t = t.replace(s, i) : t.match("[?]") ? t + "&" + a : t + "?" + a
			},
			_getQueryString: function(t) {
				var e = new RegExp("(^|&)" + t + "=([^&]*)(&|$)","i")
					, i = window.location.search.substr(1).match(e);
				return null != i ? decodeURI(i[2]) : null
			},
			_select: function() {
				var t = this
					, e = window.location.href;
				jQuery(t.child).each(function() {
					var i = jQuery(this).attr("name") + "=" + jQuery(this).attr("rel")
						, n = new RegExp(encodeURI(i),"g");
					if (n.test(e)) {
						jQuery(this).addClass(t.over);
						var r = jQuery(this).attr("name");
						jQuery("[name=" + r + "]").eq(0).removeClass(t.over)
					} else
						jQuery(this).removeClass(t.over)
				})
			}
		});
		new SelectTag({
			child: ".default", /*所有默认*/
			over: 'on', /*当前选中*/
			all: ".all" /*默认全部*/
		});
    })
} ($);